import React from 'react';
import { Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { contact, info } from 'static/data';
import { useTranslation } from 'react-i18next';

import Hours from 'static/SVG/Footer/Hours.svg';
import Info from 'static/SVG/Footer/Info.svg';
import Email from 'static/SVG/Footer/Email.svg';
import Phone from 'static/SVG/Footer/Phone.svg';

export interface IFooterProps {}

const Footer: React.FC<IFooterProps> = ({}) => {
  const { t, i18n } = useTranslation();

  return (
    <footer className='fullWidth'>
      <div className='footer'>
        <div className='content'>
          <div className='heading'>
            <h1>{t('footer.heading')}</h1>
          </div>
          <div className='data'>
            <div className='section'>
              <div className='section-heading'>
                <span className='icon'>
                  <ReactSVG src={Hours} />
                </span>
                <span className='text'>{t('footer.workingHours')}</span>
              </div>
              <div className='section-data'>
                <span className='textrow text-bold mt-none'>
                  {t('footer.office')}
                </span>
                <span className='textrow text-bold'>
                  {t('footer.monday') + ' - ' + t('footer.friday')}
                </span>
                <span className='textrow mt-small'>08:00 - 20:00</span>
                <span className='textrow text-bold'>
                  {t('footer.saturday') + ' - ' + t('footer.sunday')}
                </span>
                <span className='textrow mt-small'>08:00 - 20:00</span>
              </div>
            </div>

            <div className='section'>
              <div className='section-heading'>
                <span className='icon'>
                  <ReactSVG src={Phone} />
                </span>
                <span className='text'>{t('footer.phone')}</span>
              </div>
              <div className='section-data'>
                <span className='textrow text-bold mt-none'>
                  {t('footer.reservations')}
                </span>
                <span className='textrow'>
                  <a
                    href={`tel:${contact.mobile}`}
                  >{`Mob: ${contact.mobile}`}</a>
                </span>
                <span className='textrow mt-small'>
                  <a
                    href={`tel:${contact.telephone}`}
                  >{`Tel: ${contact.telephone}`}</a>
                </span>

                <span className='textrow text-bold'>{t('footer.support')}</span>
                <span className='textrow'>
                  <a
                    href={`tel:${contact.mobile}`}
                  >{`Mob: ${contact.mobile}`}</a>
                </span>
                <span className='textrow mt-small'>
                  <a
                    href={`tel:${contact.telephone}`}
                  >{`Tel: ${contact.telephone}`}</a>
                </span>
              </div>
            </div>

            <div className='section'>
              <div className='section-heading'>
                <span className='icon'>
                  <ReactSVG src={Email} />
                </span>
                <span className='text'>{t('footer.email')}</span>
              </div>
              <div className='section-data'>
                <span className='textrow text-bold mt-none'>
                  {t('footer.reservations')}
                </span>
                <span className='textrow'>
                  <a href={`mailto:${contact.email}`}>{`${contact.email}`}</a>
                </span>
                <span className='textrow text-bold'>{t('footer.support')}</span>
                <span className='textrow'>
                  <a href={`mailto:${contact.email}`}>{`${contact.email}`}</a>
                </span>
              </div>
            </div>

            <div className='section'>
              <div className='section-heading'>
                <span className='icon'>
                  <ReactSVG src={Info} />
                </span>
                <span className='text'>{t('footer.info')}</span>
              </div>
              <div className='section-data'>
                <span className='textrow text-bold mt-none'>
                  {info.companyName}
                </span>
                <span className='textrow mt-none'>{info.companyType}</span>
                <span className='textrow'>{`vl. ${info.owner}`}</span>
                <span className='textrow'>{`${info.address},`}</span>
                <span className='textrow mt-none'>{`${info.post}`}</span>
                <span className='textrow'>{`MB: ${info.MB}`}</span>
                <span className='textrow'>{`OIB: ${info.OIB}`}</span>
              </div>
            </div>
          </div>
          <div className='links'>
            <Link to='/#home'>{t('menu.home')}</Link>
            <Link to='/#fleet'>{t('menu.fleet')}</Link>
            <Link to='/locations'>{t('menu.locations')}</Link>
            <Link to='/contact'>{t('menu.contact')}</Link>
            <Link to='/privacypolicy'>{t('privacy.link')}</Link>
            <Link to='/termsofservice'>{t('terms.link')}</Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export { Footer };

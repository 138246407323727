import React, { useEffect, useState } from 'react';
import { I18nContext, useTranslation } from 'react-i18next';
import { storage } from '../../../Firebase';
import { Loading } from 'components/Loading';
import Air from 'static/SVG/Car/Aircondition.svg';
import Bags from 'static/SVG/Car/Bags.svg';
import Gear from 'static/SVG/Car/Gear.svg';
import Persons from 'static/SVG/Car/Persons.svg';
import { ReactSVG } from 'react-svg';

export interface ICarProps {
  car: ICar;
  navigation: any;
  selectedFilter: number;
}

export interface ICar {
  id: any;
  name: any;
  type: any;
  persons: any;
  manual: any;
  image: any;
  bags: any;
  automatic: any;
  airConditioning: any;
  sorting: any;
}

const Car: React.FC<ICarProps> = ({ car, navigation, selectedFilter }) => {
  const { t, i18n } = useTranslation();
  const [imageUrl, setImageUrl] = useState<string>();
  const [isImageLoading, setIsImageLoading] = useState<boolean>(true);

  useEffect(() => {
    if (car.image) {
      storage
        .ref(car.image)
        .getDownloadURL()
        .then((url) => {
          setImageUrl(url);
        })
        .finally(() => setIsImageLoading(false));
    }
  }, []);

  const handleGetQuote = () => {
    navigation.push({
      pathname: '/GetQuote/' + car.id,
      filter: selectedFilter,
    });
  };

  let transmission = null;

  if (car.automatic && car.manual) {
    transmission = (
      <span>{`${t('fleet.car.manual')} ${t('fleet.car.and')} ${t(
        'fleet.car.automatic'
      )}`}</span>
    );
  } else if (car.automatic) {
    transmission = <span>{`${t('fleet.car.automatic')}`}</span>;
  } else {
    transmission = <span>{`${t('fleet.car.manual')}`}</span>;
  }

  return (
    <div className={`fleet-car animated05 zoomIn`}>
      <div className='mobile-wrapper'>
        <div className='info-container'>
          <div className='top'>
            <div>
              <span className='name'>{car.name}</span>
              <span className='pipe' />
              <span className='type'>{car.type}</span>
            </div>
          </div>
          <div className='bottom'>
            <div className='left-desktop'>
              <ul>
                <li>
                  <ReactSVG src={Air} />
                  <span>{t('fleet.car.airConditioning')}</span>
                </li>
                <li>
                  <ReactSVG src={Gear} />
                  {transmission}
                </li>
                <li>
                  <ReactSVG src={Persons} />
                  <span>
                    {car.persons + ' '}
                    {car.persons < 5
                      ? t('fleet.car.persons')
                      : t('fleet.car.person')}
                  </span>
                </li>
                <li>
                  <ReactSVG src={Bags} />
                  <span>
                    {car.bags + ' '}
                    {i18n.language === 'hr'
                      ? car.bags < 2 || car.bags > 4
                        ? t('fleet.car.bag')
                        : t('fleet.car.bags')
                      : car.bags > 1
                      ? t('fleet.car.bags')
                      : t('fleet.car.bag')}
                  </span>
                </li>
              </ul>
            </div>
            <div className='left-mobile'>
              <ul>
                <li>
                  <ReactSVG src={Air} />
                  <span>{t('fleet.car.airConditioning')}</span>
                </li>
                <li>
                  <ReactSVG src={Gear} />
                  {transmission}
                </li>
              </ul>
              <ul>
                <li>
                  <ReactSVG src={Persons} />
                  <span>
                    {car.persons + ' '}
                    {t('fleet.car.persons')}
                  </span>
                </li>
                <li>
                  <ReactSVG src={Bags} />
                  <span>
                    {car.bags + ' '}
                    {t('fleet.car.bags')}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className='image-container'>
          {isImageLoading ? (
            <Loading width={40} />
          ) : (
            <img src={imageUrl} alt={car.name} />
          )}
        </div>

        <div className='right'>
          <div className='mileage'>{t('fleet.car.unlimitedMileage')}</div>
          <div>
            <button
              onClick={() => {
                handleGetQuote();
              }}
              className='btnQuote'
            >
              {t('fleet.car.getAQuote')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Car };

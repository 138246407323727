import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  withScriptjs,
  withGoogleMap,
  GoogleMap as GoogleMAP,
  Marker,
  InfoWindow,
} from 'react-google-maps';
const Headquartes = require('../../static/SVG/Locations/Marker.svg');
const Flag = require('../../static/SVG/Locations/Flag.svg');

const GoogleMap = withScriptjs(
  withGoogleMap((props: any) => {
    const { locations } = props;

    const { t } = useTranslation();
    const [infoVisible, setInfoVisible] = useState<any>({});
    // const infoWindows = locations.map((item:any)=>{

    // });
    const [center, setCenter] = useState<any>(props.mapCenter);
    const [zoom, setZoom] = useState<number>(props.zoom);
    const [locationActive, setLocationActive] = useState<any>(
      props.locationPressed
    );
    useEffect(() => {
      if (props.mapCenter) setCenter(props.mapCenter);
    }, [props.mapCenter]);
    useEffect(() => {
      if (props.zoom) setZoom(props.zoom);
    }, [props.zoom]);
    useEffect(() => {
      if (props.locationPressed) {
        setLocationActive(props.locationPressed);
        updateInfoWindows(props.locationPressed);
      }
    }, [props.locationPressed]);

    const updateInfoWindows = (location: any) => {
      let infoVisibleUpdate = infoVisible;
      const keys = Object.keys(infoVisible);
      keys.forEach((item) => {
        infoVisibleUpdate[item] = false;
      });
      infoVisibleUpdate[location.id] = true;
      setInfoVisible(infoVisibleUpdate);
    };
    return (
      <GoogleMAP
        defaultZoom={zoom}
        defaultCenter={{
          lat: center.lat, // latitude for the center of the map
          lng: center.lng, // longitude for the center of the map
        }}
        defaultOptions={{
          disableDefaultUI: true, // disable default map UI
          draggable: true, // make map draggable
          keyboardShortcuts: false, // disable keyboard shortcuts
          scaleControl: true, // allow scale controle
          scrollwheel: true, // allow scroll wheel
          // styles: styles, // change default map styles
        }}
      >
        {locations.map((item: any, index: number) => (
          <Marker
            key={index}
            icon={item.isHeadquarter ? Headquartes : Flag}
            position={{
              lat: item.coordinates.lat, // latitude to position the marker
              lng: item.coordinates.lng, // longitude to position the marker
            }}
            onClick={() => setInfoVisible({ ...infoVisible, [item.id]: true })}
          >
            {infoVisible[item.id] && (
              <InfoWindow
                position={{
                  lat: item.coordinates.lat,
                  lng: item.coordinates.lng,
                }}
                onCloseClick={() =>
                  setInfoVisible({ ...infoVisible, [item.id]: false })
                }
              >
                <div className='map-info'>
                  {item.isHeadquarter ? (
                    <>
                      <h3 className='heading'>{item.name}</h3>
                      <h6 className='subheading'>
                        {t('locations.infoWindow.headquarter')}
                      </h6>
                    </>
                  ) : (
                    <>
                      <h3 className='heading'>{item.name}</h3>
                      <h6 className='subheading'>
                        {item.fee ? t('locations.fee') : t('locations.free')}
                      </h6>
                    </>
                  )}

                  <div
                    className='overlay'
                    onClick={() =>
                      setInfoVisible({ ...infoVisible, [item.id]: false })
                    }
                  />
                </div>
              </InfoWindow>
            )}
          </Marker>
        ))}
      </GoogleMAP>
    );
  })
);

export { GoogleMap };

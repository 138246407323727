import React from 'react';
import { ReactSVG } from 'react-svg';
import { contact } from 'static/data';
import mail from 'static/SVG/Floating/mail.svg';
import phone from 'static/SVG/Floating/phone.svg';

export interface IFloatingContactProps {}

const FloatingContact: React.FC<IFloatingContactProps> = ({}) => {
  return (
    <div className='floating-contact-container'>
      <a href={`mailto:${contact.email}`} className='mail'>
        <ReactSVG src={mail} />
      </a>

      <a href={`tel:${contact.mobile}`} className='phone'>
        <ReactSVG src={phone} />
      </a>
    </div>
  );
};

export { FloatingContact };

import React, { useState } from 'react';
import ArrowDown from 'static/SVG/Reservation/ArrowDownGray.svg';
import ArrowUp from 'static/SVG/Reservation/ArrowUpGray.svg';

import 'simplebar';
import 'simplebar/dist/simplebar.css';

import { ReactSVG } from 'react-svg';

export interface IDropdownProps {
  placeholder: string;
  options: string[];
  onSelect?: (value: string) => void;
  error?: string | null;
  onFocus?: Function;
}

const Dropdown: React.FC<IDropdownProps> = ({
  placeholder,
  options,
  onSelect,
  error,
  onFocus,
}) => {
  const [selected, setSelected] = useState<string | null>(null);
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);

  const handleSelection = (option: string) => {
    setSelected(option);
    setIsCollapsed(true);
    onSelect && onSelect(option);
  };
  return (
    <div
      className={`dropdown ${isCollapsed ? 'colapsed' : 'visible'}`}
      onBlur={() => setIsCollapsed(true)}
      tabIndex={0}
      onFocus={() => onFocus && onFocus()}
    >
      <div
        className='dropdown-selected'
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        {error && <span className={`text error`}>{error}</span>}
        {!error && (
          <span className={`text ${selected ? 'selected' : ''}`}>
            {selected ? selected : placeholder}
          </span>
        )}

        <ReactSVG src={isCollapsed ? ArrowDown : ArrowUp} />
      </div>
      <div
        className={`dropdown-options-container ${
          isCollapsed ? 'collapsed' : 'visible'
        }`}
        data-simplebar
      >
        <ul>
          {options.map((option: string, i: number) => (
            <li
              key={i}
              onClick={() => handleSelection(option)}
              className={`${option === selected ? 'selected' : ''}`}
            >
              {option}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export { Dropdown };

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Services } from 'components/Services';
import { Footer } from 'components/Footer';
import { scrollToTop } from 'utils';
import Helmet from 'react-helmet';
import { Transfers } from 'components/Transfers';

export interface IPrivacyPolicyProps {}

const PrivacyPolicy: React.FC<IPrivacyPolicyProps> = ({}) => {
  const { t } = useTranslation();
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <div className='fullWidth'>
      <div className='privacy'>
        <div className='section-top'>
          <div className='privacy-content'>
            <h1>{t('privacy.headingMain')}</h1>
          </div>
        </div>
        <main className='main-container'>
          <div className='privacy-content'>
            <p>
              <b>Company: </b>BRATTIA TRAVEL
            </p>
            <p>
              <b>Address: </b>Hrvatskih velikana bb 21400 Supetar
            </p>
            <br />
            <p>
              <b>Registry information: </b>travel agency
            </p>
            <p>
              <b>Designation class: </b>79.11
            </p>
            <p>
              <b>Registration number of the bussiness entity: </b>97638102
            </p>
            <h2>General</h2>
            <p>
              Brattia Travel (brac.rent) processes your data only for the
              purposes justified by our services. We collect data so we could
              provide a vehicle reservation service, conclude a rental agreement
              and provide a bill, respond to your inquiries or complaints. Some
              data that we collect may be considered personal and we are
              protecting it in accordance with EU Regulation 2016/679 (General
              Data Protection Act).  We have the appropriate technical and
              administrative security measures to ensure that our user’s data is
              protected from unauthorized or accidental access. If you have
              additional questions about our Privacy Policy, do not hesitate to
              contact us.  Our aim is to ensure the maximum level of data
              protection and data security.
            </p>

            <div className='divider' />
            <h2>Consent</h2>
            <p>
              By using our website, you approval to our Privacy Policy and agree
              to its terms.
            </p>
            <div className='divider' />
            <h2>Third Party Privacy Policies</h2>
            <p>
              Brattia Travel (brac.rent) may submit your data to third parties
              only in case if:
            </p>
            <p>
              The law require us to deliver your data or for the protection of
              the rights and property of Brattia Travel. Also we may submit your
              data to prevent or limit the damage that could be made to Brattia
              Travel. In case you contact us directly, we might need additional
              information about you such as your full name, email, contact
              number and some other information.
            </p>
            <div className='divider' />
            <h2>Your rights (GDPR Data Protection Rights)</h2>
            <p>
              We shall inform you at your written request and within a
              reasonable period of time of the type of data you stored with us,
              the reason of the storage and possible recipients or types of
              recipient. We will also correct, delete or/and block personal data
              for further processing at your request within the limits of what
              is permitted by law, if it proves to be factually incorrect or
              irrelevant for the processing.
            </p>
            <ul>
              <li>
                You have the right to request copies of your personal data.
              </li>
              <li>
                You have the right to request that we correct some information
                you believe is inaccurate{' '}
              </li>
              <li>
                You have the right to request that we erase your personal data,
                (by certain conditions)
              </li>
              <li>
                You have the right to request that we restrict the processing of
                your personal data, (by certain conditions)
              </li>
              <li>
                You have the right to object to our processing of your personal
                data, (by certain conditions)
              </li>
            </ul>
            <div className='divider' />
            <h2>Contact</h2>
            <p>
              If you need or you want to know more about your personal data, you
              can contact us by e-mail: brattia.travel@gmail.com or by postal
              service on address:
            </p>
            <br />
            <p>
              <b>Brattia Travel, putnička agencija</b>
            </p>

            <p>
              <b>Hrvatskih velikana bb</b>
            </p>
            <p>
              <b>21400 Supetar Brač </b>
            </p>
            <p>
              <b>Croatia</b>
            </p>
          </div>
        </main>
      </div>
      <Services />
      <Transfers />
      <Footer />
      <Helmet>
        <title>Brattia Travel - Privacy policy</title>
        <meta name='description' content='Rent a car Brattia Travel.'></meta>
      </Helmet>
    </div>
  );
};

export { PrivacyPolicy };

import React, { useEffect } from 'react';

import AllDay from 'static/SVG/Extras/AllDay.svg';
import FreeDelivery from 'static/SVG/Extras/FreeDelivery.svg';
import HappyCustomers from 'static/SVG/Extras/HappyCustomers.svg';
import LowRates from 'static/SVG/Extras/LowRates.svg';
import NewVehicles from 'static/SVG/Extras/NewVehicles.svg';
import OnlineBooking from 'static/SVG/Extras/OnlineBooking.svg';

import { ReactSVG } from 'react-svg';

import { useTranslation } from 'react-i18next';

export interface IServicesProps {
  addTopPadding?: boolean;
}

const Services: React.FC<IServicesProps> = ({ addTopPadding = false }) => {
  const { t } = useTranslation();

  return (
    <div className='fullWidth'>
      <div className='services-container'>
        <div className='services-content'>
          <h2>{t('services.text')}</h2>
          <div className='services-desktop'>
            <div className='service'>
              <div className='circle'>
                <ReactSVG src={FreeDelivery} />
              </div>
              <span>{t('services.freeDelivery')}</span>
            </div>
            <div className='service'>
              <div className='circle'>
                <ReactSVG src={LowRates} />
              </div>

              <span>{t('services.lowRates')}</span>
            </div>
            <div className='service'>
              <div className='circle'>
                <ReactSVG src={NewVehicles} />
              </div>

              <span>{t('services.newVehicles')}</span>
            </div>
            <div className='service'>
              <div className='circle'>
                <ReactSVG src={AllDay} />
              </div>

              <span>{t('services.247')}</span>
            </div>
            <div className='service'>
              <div className='circle'>
                <ReactSVG src={HappyCustomers} />
              </div>

              <span>{t('services.happyCustomers')}</span>
            </div>
            <div className='service'>
              <div className='circle'>
                <ReactSVG src={OnlineBooking} />
              </div>

              <span>{t('services.onlineBooking')}</span>
            </div>
          </div>

          <div
            className={`services-mobile ${addTopPadding ? 'topPadding' : ''}`}
          >
            <div className='row'>
              <div className='service'>
                <div className='circle'>
                  <ReactSVG src={FreeDelivery} />
                </div>
                <span>{t('services.freeDelivery')}</span>
              </div>
              <div className='service'>
                <div className='circle'>
                  <ReactSVG src={LowRates} />
                </div>

                <span>{t('services.lowRates')}</span>
              </div>
            </div>
            <div className='row'>
              <div className='service'>
                <div className='circle'>
                  <ReactSVG src={NewVehicles} />
                </div>

                <span>{t('services.newVehicles')}</span>
              </div>
              <div className='service'>
                <div className='circle'>
                  <ReactSVG src={AllDay} />
                </div>

                <span>{t('services.247')}</span>
              </div>
            </div>
            <div className='row'>
              <div className='service'>
                <div className='circle'>
                  <ReactSVG src={HappyCustomers} />
                </div>

                <span>{t('services.happyCustomers')}</span>
              </div>
              <div className='service'>
                <div className='circle'>
                  <ReactSVG src={OnlineBooking} />
                </div>

                <span>{t('services.onlineBooking')}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Services };

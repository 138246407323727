import React, { useState } from 'react';
import { ReactComponent as MenuBlack } from 'static/SVG/Header/MenuBlack.svg';
import { ReactComponent as MenuRed } from 'static/SVG/Header/MenuRed.svg';
import { ReactComponent as ArrowDownBlack } from 'static/SVG/Header/ArrowDownBlack.svg';
import { ReactComponent as ArrowUpBlack } from 'static/SVG/Header/ArrowUpBlack.svg';
import { HashLink as Link } from 'react-router-hash-link';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import Logo from 'static/Logo.png';
export interface IHeaderProps {}

const Header: React.FC<IHeaderProps> = () => {
  const [isNavVisible, setIsNavVisible] = useState<boolean>(false);
  const [showLanguageSelection, setShowLanguageSelection] = useState<boolean>(
    false
  );

  const history = useHistory();
  const { t, i18n } = useTranslation();
  const setLanguage = (lng: 'hr' | 'en') => {
    i18n.changeLanguage(lng);
    localStorage.setItem('language', lng);
  };

  return (
    <header className='header-container fullWidth'>
      <div className='header-content'>
        <div
          onClick={() => {
            history.push('/');
            window.scroll(0, 0);
          }}
          className='logo'
        >
          <img src={Logo}></img>
        </div>
        <div
          className='hamburger'
          onClick={() => setIsNavVisible(!isNavVisible)}
        >
          {!isNavVisible && <MenuBlack />}
          {isNavVisible && <MenuRed />}
        </div>
        <nav className='nav-desktop'>
          <ul>
            <li>
              <Link to='/#home'>{t('menu.home')}</Link>
            </li>
            <li>
              <Link to='/#fleet'>{t('menu.fleet')}</Link>
            </li>
            <li>
              <Link to='/locations'>{t('menu.locations')}</Link>
            </li>
            <li>
              <Link to='/contact'>{t('menu.contact')}</Link>
            </li>
            <li className='language-selection'>
              <div className='language-selection-dropdown'>
                <button
                  onClick={() =>
                    setShowLanguageSelection(!showLanguageSelection)
                  }
                  className='language-option-selected'
                >
                  {i18n.language === 'hr' && 'HRV'}
                  {i18n.language === 'en' && 'ENG'}

                  <span>
                    {showLanguageSelection ? (
                      <ArrowUpBlack />
                    ) : (
                      <ArrowDownBlack />
                    )}
                  </span>
                </button>

                <button
                  onClick={() => {
                    setLanguage(i18n.language === 'hr' ? 'en' : 'hr');
                    setShowLanguageSelection(false);
                  }}
                  className={
                    showLanguageSelection
                      ? 'language-option'
                      : 'language-option-hidden'
                  }
                >
                  {i18n.language === 'en' && 'HRV'}
                  {i18n.language === 'hr' && 'ENG'}
                </button>
              </div>
            </li>
          </ul>
        </nav>
      </div>
      <div
        className={`nav-mobile ${isNavVisible ? 'nav-visible' : 'nav-hidden'}`}
      >
        <nav className='nav-mobile-content medium-16'>
          <ul>
            <li>
              <Link onClick={() => setIsNavVisible(false)} to='/#home'>
                {t('menu.home')}
              </Link>
            </li>
            <li>
              <Link onClick={() => setIsNavVisible(false)} to='/#fleet'>
                {t('menu.fleet')}
              </Link>
            </li>
            <li>
              <Link onClick={() => setIsNavVisible(false)} to='/locations'>
                {t('menu.locations')}
              </Link>
            </li>
            <li>
              <Link onClick={() => setIsNavVisible(false)} to='/contact'>
                {t('menu.contact')}
              </Link>
            </li>
            <li className='language-options'>
              <button
                className={
                  i18n.language === 'hr' ? 'language-option-selected' : ''
                }
                onClick={() => setLanguage('hr')}
              >
                HRV
              </button>
              <div className='distancer'></div>
              <button
                onClick={() => setLanguage('en')}
                className={
                  i18n.language === 'en' ? 'language-option-selected' : ''
                }
              >
                ENG
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export { Header };

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Loading } from 'components/Loading';
import { ICar, Car } from './Car';
import { firebase } from '../../Firebase';

const FILTER_OPTIONS = [
  { id: 0, text: 'fleet.filter.all' },
  { id: 1, text: 'fleet.filter.persons4' },
  { id: 2, text: 'fleet.filter.persons5' },
  { id: 3, text: 'fleet.filter.persons7' },
  { id: 4, text: 'fleet.filter.persons9' },
  { id: 5, text: 'fleet.filter.automatic' },
  { id: 6, text: 'fleet.filter.manual' },
];

export interface IFleetProps {
  navigation: any;
}

const Fleet: React.FC<IFleetProps> = ({ navigation }) => {
  const { t } = useTranslation();
  const [selectedFilter, setSelectedFilter] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [cars, setCars] = useState<ICar[]>([]);

  let renderCars: ICar[] = [];
  switch (selectedFilter) {
    case 1:
      renderCars = cars.filter((item: ICar) => item.persons >= 4);
      break;
    case 2:
      renderCars = cars.filter((item: ICar) => item.persons >= 5);
      break;
    case 3:
      renderCars = cars.filter((item: ICar) => item.persons >= 7);
      break;

    case 4:
      renderCars = cars.filter((item: ICar) => item.persons >= 9);
      break;
    case 5:
      renderCars = cars.filter((item: ICar) => item.automatic);
      break;
    case 6:
      renderCars = cars.filter((item: ICar) => item.manual);
      break;
    default:
      renderCars = [...cars];
      break;
  }
  useEffect(() => {
    const db = firebase.firestore();
    db.collection('cars')
      .get()
      .then((querySnapshot) => {
        let carsFromDB: ICar[] = [];
        querySnapshot.forEach((doc) => {
          const car = doc.data();
          carsFromDB.push({
            name: car.name,
            id: doc.id,
            image: car.image,
            type: car.type,
            manual: car.manual,
            automatic: car.automatic,
            airConditioning: car.airConditioning,
            persons: car.persons,
            bags: car.bags,
            sorting: car.sorting,
          });
        });
        const sorted = carsFromDB.sort((a, b) => a.sorting - b.sorting);
        setCars([...sorted]);
        setIsLoading(false);
      });
  }, []);
  return (
    <div className='fleet-container fullWidth'>
      <div className='fleet-content'>
        <div className='filter-section'>
          <h3>{t('fleet.filter.ourCarFleet')}:</h3>
          <div className='filter'>
            <ul>
              {FILTER_OPTIONS.map(({ id, text }) => (
                <li
                  key={id}
                  onClick={() => setSelectedFilter(id)}
                  className={selectedFilter === id ? 'selected' : ''}
                >
                  {t(text)}
                </li>
              ))}
            </ul>
          </div>
        </div>

        {/* cars section */}
        {isLoading && (
          <div className='loading-container'>
            <Loading
              textStyle={{ fontSize: '22px' }}
              text={t('fleet.loadingCars')}
            />
          </div>
        )}

        {!isLoading && (
          <div className='cars-container'>
            {renderCars.map((car: ICar, i: number) => (
              <Car
                key={car.id}
                car={car}
                navigation={navigation}
                selectedFilter={selectedFilter}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export { Fleet };

import * as FIREBASE from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';

const firebaseConfig = {
  apiKey: 'AIzaSyAulWx-qA41myuSbJR9tIR3PskKf12xQPA',
  authDomain: 'brattia-travel.firebaseapp.com',
  databaseURL: 'https://brattia-travel.firebaseio.com',
  projectId: 'brattia-travel',
  storageBucket: 'brattia-travel.appspot.com',
  messagingSenderId: '590704811404',
  appId: '1:590704811404:web:4d0cb6fcc5f9dc54fccaa9',
  measurementId: 'G-PGXYWN7VYS',
};

if (!FIREBASE.apps.length) {
  FIREBASE.initializeApp(firebaseConfig);
}
const firebase = FIREBASE;
const storage = firebase.storage();

export { firebase, storage };

import React from 'react';
import BannerTransfer from '../../static/Photo/BannerTransfer.png';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export interface ITransfersProps {}

const Transfers: React.FC<ITransfersProps> = ({}) => {
  const { t } = useTranslation();

  return (
    <div className='fullWidth'>
      <div className='transfers-container'>
        <div className='transfers-content'>
          <div className='row'>
            <h2>{t('transfer.text')}</h2>
            <div className='image'>
              <img src={BannerTransfer} alt='Van' />
            </div>
          </div>
          <div className='row'>
            <button>
              <Link to='/contact'>{t('transfer.getYourTransfer')}</Link>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Transfers };

import React, { useState, useEffect } from 'react';
import { ICar } from 'components/Fleet/Car';
import { storage } from '../../Firebase';
import { Loading } from 'components/Loading';
import Air from 'static/SVG/Car/Aircondition.svg';
import Bags from 'static/SVG/Car/Bags.svg';
import Gear from 'static/SVG/Car/Gear.svg';
import Persons from 'static/SVG/Car/Persons.svg';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';

export interface ICarProps {
  car: ICar;
}

const Car: React.FC<ICarProps> = ({ car }) => {
  const [imageUrl, setImageUrl] = useState<string>();
  const [isImageLoading, setIsImageLoading] = useState<boolean>(true);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (car.image) {
      storage
        .ref(car.image)
        .getDownloadURL()
        .then((url) => {
          setImageUrl(url);
        })
        .finally(() => setIsImageLoading(false));
    }
  }, []);

  let transmission = null;

  if (car.automatic && car.manual) {
    transmission = (
      <span>{`${t('fleet.car.manual')} and ${t('fleet.car.automatic')}`}</span>
    );
  } else if (car.automatic) {
    transmission = <span>{`${t('fleet.car.automatic')}`}</span>;
  } else {
    transmission = <span>{`${t('fleet.car.manual')}`}</span>;
  }
  return (
    <div className='car-container'>
      <div className='image-container'>
        {isImageLoading ? (
          <Loading width={40} />
        ) : (
          <img src={imageUrl} alt={car.name} />
        )}
      </div>
      <div className='info'>
        <div className='top'>
          <span className='name'>{car.name}</span>
          <span className='pipe' />
          <span className='type'>{car.type}</span>
        </div>
        <div className='bottom'>
          <ul>
            <li>
              <ReactSVG src={Air} />
              <span>{t('fleet.car.airConditioning')}</span>
            </li>
            <li>
              <ReactSVG src={Gear} />
              {transmission}
            </li>
          </ul>
          <ul>
            <li>
              <ReactSVG src={Persons} />
              <span>
                {car.persons + ' '}
                {t('fleet.car.persons')}
              </span>
            </li>
            <li>
              <ReactSVG src={Bags} />
              <span>
                {car.bags + ' '}
                {i18n.language === 'hr'
                  ? car.bags < 2 || car.bags > 4
                    ? t('fleet.car.bag')
                    : t('fleet.car.bags')
                  : car.bags > 1
                  ? t('fleet.car.bags')
                  : t('fleet.car.bag')}
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export { Car };

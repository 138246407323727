const HOST = 'https://us-central1-brattia-travel.cloudfunctions.net/app';

const contact = {
  mobile: '+385 91 521 1748',
  telephone: '+385 21 584 351',
  email: 'info@brac.rent',
};

const info = {
  companyName: 'Brattia Travel',
  companyType: 'putnička agencija',
  owner: 'Ela Mandić',
  address: 'Hrvatskih velikana bb',
  post: '21400 Supetar',
  MB: '97638102',
  OIB: '48617220484',
};

const locations: {
  id: number;
  name: string;
  fee: boolean;
  pickUp: boolean;
  dropOff: boolean;
  coordinates: { lat: number; lng: number };
  isHeadquarter?: boolean;
}[] = [
  {
    id: 1,
    name: 'Supetar',
    pickUp: true,
    dropOff: true,
    coordinates: { lat: 43.3825, lng: 16.551291 },
    fee: false,
    isHeadquarter: true,
  },
  {
    id: 2,
    name: 'Milna',
    pickUp: true,
    dropOff: true,
    coordinates: { lat: 43.32967, lng: 16.448971 },
    fee: false,
  },
  {
    id: 3,
    name: 'Airport Brač',
    pickUp: true,
    dropOff: true,
    coordinates: { lat: 43.2858269, lng: 16.6793529 },
    fee: false,
  },
  {
    id: 4,
    name: 'Nerežišća',
    pickUp: true,
    dropOff: true,
    coordinates: { lat: 43.33055, lng: 16.57649 },
    fee: false,
  },
  {
    id: 5,
    name: 'Pražnica',
    pickUp: true,
    dropOff: true,
    coordinates: { lat: 43.32061, lng: 16.69584 },
    fee: false,
  },

  {
    id: 6,
    name: 'Novo Selo',
    pickUp: true,
    dropOff: true,
    coordinates: { lat: 43.306127, lng: 16.84391 },
    fee: false,
  },
  {
    id: 7,
    name: 'Bobovišča',
    pickUp: true,
    dropOff: true,
    coordinates: { lat: 43.34471, lng: 16.47455 },
    fee: false,
  },
  {
    id: 8,
    name: 'Sutivan',
    pickUp: true,
    dropOff: true,
    coordinates: { lat: 43.38435, lng: 16.47855 },
    fee: false,
  },
  {
    id: 9,
    name: 'Bol',
    pickUp: true,
    dropOff: true,
    coordinates: { lat: 43.262738, lng: 16.654265 },
    fee: false,
  },
  {
    id: 10,
    name: 'Mirca',
    pickUp: true,
    dropOff: true,
    coordinates: { lat: 43.376577, lng: 16.522605 },
    fee: false,
  },

  {
    id: 11,
    name: 'Ložišća',
    pickUp: true,
    dropOff: true,
    coordinates: { lat: 43.34702, lng: 16.483098 },
    fee: false,
  },
  {
    id: 12,
    name: 'Povlja',
    pickUp: true,
    dropOff: true,
    coordinates: { lat: 43.330971, lng: 16.837729 },
    fee: false,
  },
  {
    id: 13,
    name: 'Selca',
    pickUp: true,
    dropOff: true,
    coordinates: {
      lat: 43.29597,
      lng: 16.846579,
    },
    fee: false,
  },
  {
    id: 14,
    name: 'Donji Humac',
    pickUp: true,
    dropOff: true,
    coordinates: { lat: 43.339493, lng: 16.561027 },
    fee: false,
  },
  {
    id: 15,
    name: 'Postira',
    pickUp: true,
    dropOff: true,
    coordinates: { lat: 43.374498, lng: 16.633065 },
    fee: false,
  },

  {
    id: 16,
    name: 'Sumartin',
    pickUp: true,
    dropOff: true,
    coordinates: { lat: 43.286871, lng: 16.873351 },
    fee: false,
  },
  {
    id: 17,
    name: 'Dol',
    pickUp: true,
    dropOff: true,
    coordinates: { lat: 43.346344, lng: 16.624 },
    fee: false,
  },
  {
    id: 18,
    name: 'Pučišča',
    pickUp: true,
    dropOff: true,
    coordinates: { lat: 43.350357, lng: 16.729272 },
    fee: false,
  },
  {
    id: 19,
    name: 'Murvica',
    pickUp: true,
    dropOff: true,
    coordinates: { lat: 43.269982, lng: 16.600133 },
    fee: false,
  },
  {
    id: 20,
    name: 'Škrip',
    pickUp: true,
    dropOff: true,
    coordinates: { lat: 43.355584, lng: 16.60353 },
    fee: false,
  },
  {
    id: 21,
    name: 'Airport Split',
    pickUp: true,
    dropOff: true,
    coordinates: { lat: 43.536852021247086, lng: 16.299046386679205 },
    fee: true,
  },
];

export { contact, locations, info, HOST };

import React, { useState, useEffect } from 'react';

import { Footer } from 'components/Footer';
import { Services } from 'components/Services';
import CarImage from '../../static/Photo/CarMain.png';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { Dropdown } from 'components/Dropdown';
import Datepicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale } from 'react-datepicker';
import hr from 'date-fns/locale/hr';
import { ReactSVG } from 'react-svg';
import Calendar from 'static/SVG/Reservation/Calendar.svg';
import { Fleet } from 'components/Fleet';
import { ICar } from 'components/Fleet/Car';
import { firebase } from '../../Firebase';
import { Transfers } from 'components/Transfers';
import { locations } from 'static/data';

registerLocale('hr', hr);

export interface IHomeProps {
  history: any;
}

const Home: React.FC<IHomeProps> = ({ history }) => {
  const { t, i18n } = useTranslation();
  const [carSelected, setCarSelected] = useState<string | null>(null);
  const [pickupLocation, setPickupLocation] = useState<string | null>(null);
  const [pickupDate, setPickupDate] = useState<Date | null>(null);
  const [returnDate, setReturnDate] = useState<Date | null>(null);
  const [carError, setCarError] = useState<string | null>(null);
  const [cars, setCars] = useState<ICar[]>([]);

  const [returnDateVisible, setReturnDateVisible] = useState<boolean>(false);

  useEffect(() => {
    const db = firebase.firestore();
    db.collection('cars')
      .get()
      .then((querySnapshot) => {
        let carsFromDB: ICar[] = [];
        querySnapshot.forEach((doc) => {
          const car = doc.data();
          carsFromDB.push({
            name: car.name,
            id: doc.id,
            image: car.image,
            type: car.type,
            manual: car.manual,
            automatic: car.automatic,
            airConditioning: car.airConditioning,
            persons: car.persons,
            bags: car.bags,
            sorting: car.sorting,
          });
        });
        const sorted = carsFromDB.sort((a, b) => a.sorting - b.sorting);
        setCars([...sorted]);
      });
  }, []);

  const handleReservationSubmit = () => {
    if (!carSelected) {
      setCarError(t('home.reservation.carNotSelected'));
    }

    //redirect to get a qoute with car id and rest of parameters

    const car = cars.filter((item: ICar) => item.name === carSelected)[0];

    if (car) {
      history.push({
        pathname: '/GetQuote/' + car.id,
        params: {
          pickupDate,
          returnDate,
          pickupLocation,
        },
      });
    }
  };

  const inputContainers = document.getElementsByClassName(
    'react-datepicker-wrapper'
  );
  if (inputContainers && inputContainers.length > 0) {
    inputContainers[0].children[0].children[0].setAttribute('readonly', 'true');
    inputContainers[1].children[0].children[0].setAttribute('readonly', 'true');
  }
  return (
    <div className='fullWidth home' id='home'>
      <section className='section-top'>
        <div className='section-top-content'>
          <div className='car-container'>
            <div className='img-container animated05 bounceIn'>
              <img src={CarImage} alt='Car header' />
            </div>
          </div>
          <div className='text-reservation-wrap'>
            <div className='gradient'></div>
            <div className='text-container animated bounceIn'>
              <h1 className='headingOne'>{t('home.top.headingOne')}</h1>
              <h1 className='red'>{t('home.top.headingTwoLine1')}</h1>
              <h1 className='red'>{t('home.top.headingTwoLine2')}</h1>
            </div>
            <div className='reservation-container animated05 bounceIn'>
              <div className='item'>
                <Dropdown
                  onFocus={() => setCarError(null)}
                  error={carError}
                  onSelect={(value: string) => setCarSelected(value)}
                  placeholder={t('home.reservation.pickCar')}
                  options={cars.map((item: ICar) => item.name)}
                />
              </div>
              <div className='item'>
                <Dropdown
                  onSelect={(value: string) => setPickupLocation(value)}
                  placeholder={t('home.reservation.pickupLocation')}
                  options={locations
                    .filter((item: any) => item.pickUp)
                    .map((item: any) => item.name)}
                />
              </div>
              <div className='item'>
                <span className='calendar-icon'>
                  <ReactSVG src={Calendar} />
                </span>
                <div>
                  <Datepicker
                    selected={pickupDate}
                    locale={i18n.language}
                    onChange={(date: Date | null) => {
                      if (returnDate && date && date > returnDate) {
                        setReturnDate(null);
                      }
                      setPickupDate(date);
                    }}
                    placeholderText={t('home.reservation.pickupDate')}
                    minDate={new Date()}
                    onFocus={() => {}}
                  />
                </div>
              </div>
              <div className='item'>
                <span className='calendar-icon'>
                  <ReactSVG src={Calendar} />
                </span>
                <div>
                  <Datepicker
                    selected={returnDate}
                    locale={i18n.language}
                    onChange={(date: Date | null) => {
                      setReturnDate(date);
                      setReturnDateVisible(false);
                    }}
                    onFocus={() => {
                      setReturnDateVisible(true);
                    }}
                    onBlur={() => {
                      setReturnDateVisible(false);
                    }}
                    placeholderText={t('home.reservation.returnDate')}
                    minDate={pickupDate ? pickupDate : new Date()}
                  />
                </div>
              </div>
              <div className='item'>
                <button
                  className='next'
                  onClick={() => handleReservationSubmit()}
                >
                  {t('home.reservation.next')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Services addTopPadding={returnDateVisible} />
      <section className='section-fleet' id='fleet'>
        <Fleet navigation={history} />
      </section>
      <Transfers />

      <Footer />
      <Helmet>
        <title>Brattia Travel</title>
        <meta
          name='description'
          content='Car rental service. Brattia Travel is small rent a car company located in island of Brač. Rent a car Brac. Rent a car Supetar. Rent a car Bol. Rent a car Milna. Rent a car Sutivan. Flexible rents, great prices. '
        ></meta>
        <meta
          name='viewport'
          content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0'
        />
      </Helmet>
    </div>
  );
};

export { Home };

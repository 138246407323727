import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Services } from 'components/Services';
import { Footer } from 'components/Footer';
import { scrollToTop } from 'utils';
import Helmet from 'react-helmet';

export interface ITermsOfServiceProps {}

const TermsOfService: React.FC<ITermsOfServiceProps> = ({}) => {
  const { t } = useTranslation();
  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div className='fullWidth'>
      <div className='terms'>
        <div className='section-top'>
          <div className='terms-content'>
            <h1>{t('terms.headingMain')}</h1>
          </div>
        </div>
        <main className='main-container'>
          <div className='terms-content'>
            <h2>General terms</h2>
            Renter acknowledges and agrees with the signature on the rental
            agreement:
            <ul>
              <br />
              <li>
                To return the vehicle on time with all equipment and accessories
                received
              </li>
              <li>To take care of the technical condition of the vehicle</li>
              <li>
                Renter is not allowed to exchange parts or assemblies of the
                vehicle
              </li>
              <li>
                To use the vehicle only for his personal use and not to rent it
                or lend it to the third parties
              </li>
              <li>
                Not to overload the vehicle with persons or goods over the
                permitted maximum
              </li>
              <li>
                Renter is not allowed to cross Croatian border without lessor's
                permission
              </li>
              <li>
                To properly maintain the rented car with all necessary care
              </li>
              <li>
                Renter is not allowed to make any changes of parts on the rented
                car without previous authorization from lessor
              </li>
              <li>Costs of fuel during the rent shall be covered by renter</li>
            </ul>
            <br />
            <p>
              The quantity of fuel when returning the car must equal the amount
              of fuel when picking up the car.
            </p>
            <p>
              Renter is responsible for traffic/parking/police violations he
              caused while rental period.
            </p>
            <p>
              Renter is responsible for extremely dirty vehicle and should be
              charged for the cleaning of the vehicle.
            </p>
            <div className='divider'></div>
            <h2>Payment</h2>
            <p>
              We accept credit/debit card or cash for payments. When picking up
              the car authorization can be made on credit card or cash deposit
              taken.
            </p>
            <div className='divider'></div>
            <h2>Insurance</h2>
            <p>
              All our cars are provided with third party liability coverage.
              Excess amount depends on the category of car rented.
            </p>
            <br />
            <br />
            <b>CDW</b> – Collision Damage Waiver – basic insurance that is
            included in price of the car. The renter reduces his/her liability
            for damage to the amount of excess amount, which depends on the car
            category, and if the damage is smaller than the excess amount, the
            renter pays that smaller damage amount.
            <br />
            <b>TP</b> – Theft Protection – the renter limits his/her liability
            for this type of damage up to the amount of excess in case that car
            gets stolen. When reporting this type of damage user needs to have
            the car keys and car documents, otherwise user will be responsible
            for whole damage that was caused by car theft.
            <br />
            <b>SCDW</b> – Super Collision Damage Waiver – optional insurance,
            daily supplement for reducing renter’s liability to 0€
            <br />
            <br />
            The insurance does in no way cover:
            <ul>
              <br />
              <li>damage caused by the careless or negligent drive</li>
              <li>
                any damage not reported to the nearest police station and rental
                station
              </li>
              <li>
                damage to engine due to lack of oil or filling the car with
                wrong fuel
              </li>
              <li>
                damage caused by a driver under influence of alcohol or drugs
              </li>
              <li>damage caused by unauthorized driver</li>
              <li>
                damage done outside of the Croatia, if the border crossing
                wasn`t allowed by the lessor
              </li>
              <li>
                if user don’t follow all the steps in the part „Procedure in
                case of accident“ in the text above
              </li>
            </ul>
            <br />
            <p>
              In case if any above circumstances apply, renter is responsible
              for whole amount of the damage.
            </p>
            <div className='divider'></div>
            <h2>Fuel</h2>
            <p>
              Fuel costs during rental period will be covered by the renter.
              When returning the vehicle, quantity of fuel must be equal to the
              level of fuel received.
            </p>
            <div className='divider'></div>
            <h2>
              Car accident, malfunction, traffic violations and parking tickets
              of the leased
            </h2>
            <p>
              Renter agrees to protect the interests of lessor and the lessor`s
              insurance company in case of accident by:
            </p>
            <br />
            <ul>
              <li>
                contacting rental and police station even in case of slight
                damage and submitting to police station written report of the
                accident
              </li>
              <li>obtaining names and addresses of parties involved</li>
              <li>
                not abandoning rented vehicle without adequate safeguarding and
                security
              </li>
              <li>
                in case of greater damage or if the persons are injured is
                obligated to notify the police, to wait until the police arrives
                and makes official inquiries and immediately notify rental
                station
              </li>
              <li>
                All police statements from the accident and alcohol testing
                results must be handed over to rental station when returning the
                car.
              </li>
              <li>
                if renter fails to take steps mentioned above, he agrees to be
                liable to lessor for all losses and consequences lessor should
                suffer thereof
              </li>
            </ul>
            <br />
            <p>
              Neither of the optional insurance (CDW and SCDW) cover damage to
              the interior, windows and undercarriage of the rented car.
            </p>
            <div className='divider'></div>
            <h2>Our rates include:</h2>
            <ul>
              <li>CDW Casual damage waiver</li>
              <li>Unlimited mileage</li>
              <li>Additional driver</li>
              <li>Tax</li>
            </ul>
            <div className='divider'></div>
            <h2>Optional extras for additional costs:</h2>
            <ul>
              <li>SCDW (Full car insurance)</li>
              <li>GPS</li>
              <li>Child /Booster/Baby seat</li>
              <li>Mobile WiFi</li>
              <li>Fuel refilling service</li>
            </ul>
            <br />
            <p className='italic'>
              When collecting a vehicle, rental contract agreement is signed
              between „Lessor“ Brattia Travel, travel agency with its
              headquarters located at Hrvatskih velikana bb, 21400 Supetar, OIB:
              48617220484 and a „Lessee“ natural or legal person which is hiring
              a vehicle. Contract authorizes the use of the vehicle, defines the
              vehicle pick up and drops off, coverage, equipment and services
              included in the price and the method of payment for the rental.
              The contract also contains information about the status of
              mileage, fuel level, damage and possible shortcomings of the
              rental vehicle and other rights and obligations of both parties
              who with their signature fully accept contract at the time of
              issuing and general terms of the rental which are written on the
              rental contract.
            </p>
          </div>
        </main>
      </div>
      <Services />
      <Footer />
      <Helmet>
        <title>Brattia Travel - Terms of service</title>
        <meta name='description' content='Rent a car Brattia Travel.'></meta>
      </Helmet>
    </div>
  );
};

export { TermsOfService };

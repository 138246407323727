import React, { useState, useEffect } from 'react';
import { Route } from 'react-router';
import './styles/styles.less';

import { useTranslation } from 'react-i18next';

import { GetQuote } from 'components/GetQuote';
import { Locations } from 'components/Locations';
import { Contact } from 'components/Contact';

import { Home } from 'components/Home';
import { Header } from 'components/Header';
import { PrivacyPolicy } from 'components/PrivacyPolicy';
import { TermsOfService } from 'components/TermsOfService';
import { FloatingContact } from 'components/FloatingContact/FloatingContact';

const App = (props: any) => {
  const { i18n } = useTranslation();

  useEffect(() => {
    const language = localStorage.getItem('language');
    if (!language) {
      i18n.changeLanguage('en');
    } else {
      i18n.changeLanguage(language);
    }
  }, []);

  return (
    <div className='App'>
      <Header />
      <FloatingContact />
      <Route exact path='/' component={Home} />
      <Route
        path='/GetQuote/:carId'
        render={(props) => <GetQuote {...props} />}
      />
      <Route path='/locations' component={Locations} />
      <Route path='/contact' component={Contact} />
      <Route path='/privacypolicy' component={PrivacyPolicy} />
      <Route path='/termsofservice' component={TermsOfService} />
    </div>
  );
};

export { App };

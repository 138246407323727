import React, { ChangeEvent } from 'react';

export interface ITextInputProps {
  type?: string;
  placeholder: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur: (event: ChangeEvent<HTMLInputElement>) => void;
  value: any;
  name: string;
  width?: string;
}

const TextInput: React.FC<ITextInputProps> = ({
  type = 'text',
  placeholder,
  width = '240px',
  ...rest
}) => {
  return (
    <div className='text-input-container' style={{ width: width }}>
      <input
        className='text-input'
        type={type}
        placeholder={placeholder}
        {...rest}
      />
    </div>
  );
};

export { TextInput };

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Car } from './Car';
import { firebase } from '../../Firebase';
import { ICar } from 'components/Fleet/Car';
import { Loading } from 'components/Loading';
import { Services } from 'components/Services';
import { Footer } from 'components/Footer';
import { Formik } from 'formik';
import { TextInput } from 'components/TextInput';
import { DropdownInput } from 'components/DropdownInput';
import { HOST, locations } from 'static/data';
import Datepicker from 'react-datepicker';
import Calendar from 'static/SVG/Reservation/Calendar.svg';

import * as Yup from 'yup';

import { ReactSVG } from 'react-svg';
import { Modal } from 'components/Modal';
import { contact } from 'static/data';
import { Transfers } from 'components/Transfers';

export interface IGetQuoteProps {
  location: any;
  match: any;
  history: any;
}
export interface IExtra {
  text: string;
  name:
    | 'additionalDriver'
    | 'gpsDevice'
    | 'babySeat'
    | 'wifi'
    | 'ferryFee'
    | 'crossBorder';
  price: number;
  maxAmount?: number;
  calculation: 'daily' | 'once';
  icon: string;
}

const GetQuote: React.FC<IGetQuoteProps> = ({ location, match, history }) => {
  const { t, i18n } = useTranslation();
  const carID = match.params['carId'];
  const [car, setCar] = useState<ICar>();
  const [carError, setCarError] = useState<boolean>(false);
  const [carLoading, setCarLoading] = useState<boolean>(true);
  const [cars, setCars] = useState<ICar[]>([]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isModalVisible, setModalVisible] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const inputContainers = document.getElementsByClassName(
    'react-datepicker-wrapper'
  );
  if (inputContainers && inputContainers.length > 0) {
    inputContainers[0].children[0].children[0].setAttribute('readonly', 'true');
    inputContainers[1].children[0].children[0].setAttribute('readonly', 'true');
  }

  const validationSchema = Yup.object({
    name: Yup.string(),
    email: Yup.string()
      .email(t('validationMessage.email'))
      .required(t('validationMessage.required')),
    car: Yup.string().required(t('validationMessage.required')),
    pickupLocation: Yup.string(),
    returnLocation: Yup.string(),
    pickupDate: Yup.date().nullable(),
    returnDate: Yup.date().nullable(),
  });

  useEffect(() => {
    const db = firebase.firestore();
    const getCar = () => {
      db.collection('cars')
        .doc(carID)
        .get()
        .then((doc) => {
          if (doc.exists) {
            let data = doc.data();
            const car: ICar = {
              name: (data as any).name,
              id: (data as any).id,
              image: (data as any).image,
              type: (data as any).type,
              manual: (data as any).manual,
              automatic: (data as any).automatic,
              airConditioning: (data as any).airConditioning,
              persons: (data as any).persons,
              bags: (data as any).bags,
              sorting: (data as any).sorting,
            };
            setCarError(false);
            setCar(car);
          }
        })
        .catch(() => setCarError(true))
        .finally(() => setCarLoading(false));
    };

    const getAllCars = () => {
      db.collection('cars')
        .get()
        .then((querySnapshot) => {
          let carsFromDB: ICar[] = [];
          querySnapshot.forEach((doc) => {
            const car = doc.data();
            carsFromDB.push({
              name: car.name,
              id: doc.id,
              image: car.image,
              type: car.type,
              manual: car.manual,
              automatic: car.automatic,
              airConditioning: car.airConditioning,
              persons: car.persons,
              bags: car.bags,
              sorting: car.sorting,
            });
          });
          const sorted = carsFromDB.sort((a, b) => a.sorting - b.sorting);
          setCars([...sorted]);
        });
    };

    getCar();
    getAllCars();
    window.scroll(0, 0);
  }, []);

  let initialPickupLocation = '';
  let initialPickupDate = null;
  let initialReturnDate = null;

  if (location.params) {
    ///we have parameters from reservation box
    if (location.params.pickupLocation) {
      initialPickupLocation = location.params.pickupLocation;
    }
    if (location.params.pickupDate) {
      initialPickupDate = location.params.pickupDate;
    }
    if (location.params.returnDate) {
      initialReturnDate = location.params.returnDate;
    }
  }
  if (location.filter !== undefined) {
    //we have filter
  }

  const getCarTransmissionOptions = () => {
    if (car) {
      let options = [];
      if (car.manual) {
        options.push(t('quote.transmission.manual'));
      }
      if (car.automatic) {
        options.push(t('quote.transmission.automatic'));
      }
      return options;
    }
    return [];
  };

  let mainRender = null;
  if (carLoading) {
    mainRender = (
      <div className='loading-wrap'>
        <Loading text={t('quote.loading')} />
      </div>
    );
  } else {
    if (carError) {
      mainRender = (
        <p
          style={{
            paddingTop: '60px',
            paddingBottom: '20px',
            color: '#919191',
          }}
        >
          {t('quote.error')}
        </p>
      );
    } else {
      mainRender = (
        <div className='get-quote' id='desktop-scrollTo'>
          <p className='description'>{t('quote.description')}</p>
          <Formik
            validationSchema={validationSchema}
            onSubmit={async (values: any, { resetForm }) => {
              const dataForEmail: any = {
                ...values,
                name: values.name.length > 0 ? values.name : 'Not provided',
                pickupLocation:
                  values.pickupLocation.length > 0
                    ? values.pickupLocation
                    : 'Not provided',
                returnLocation:
                  values.returnLocation.length > 0
                    ? values.returnLocation
                    : 'Not provided',
                pickupDate: values.pickupDate
                  ? values.pickupDate.toLocaleDateString('hr')
                  : null,
                returnDate: values.returnDate
                  ? values.returnDate.toLocaleDateString('hr')
                  : null,
                transmission:
                  values.transmission.length > 0
                    ? values.transmission
                    : 'Not provided',
                phone: values.phone.length > 0 ? values.phone : 'Not provided',
                note: values.note.length > 0 ? values.note : 'Not provided',
              };
              await fetch(`${HOST}/getQuote`, {
                method: 'POST',
                body: JSON.stringify(dataForEmail),
                headers: {
                  'Content-Type': 'application/json',
                },
              })
                .then((res) => res.json())
                .then((data) => {
                  if (data.success === true) {
                    setModalVisible(true);
                    setSuccess(true);
                  } else {
                    setModalVisible(true);
                    setSuccess(false);
                  }
                })
                .catch((error) => {
                  setModalVisible(true);
                  setSuccess(false);
                })
                .finally(() => setIsSubmitting(false));
            }}
            initialValues={{
              name: '',
              email: '',
              phone: '',
              car: car?.name,
              transmission: '',
              pickupLocation: initialPickupLocation,
              returnLocation: '',
              pickupDate: initialPickupDate,
              returnDate: initialReturnDate,
              note: '',
            }}
          >
            {(props) => (
              <div className='reservation-container zoomIn animated'>
                <div className='main'>
                  <div className='car'>{car && <Car car={car} />}</div>
                  <div className='form' id='mobile-scrollTo'>
                    <div className='row'>
                      <div className='input-wrap'>
                        <TextInput
                          name='name'
                          placeholder={t('quote.name')}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          value={props.values.name}
                        />
                        {props.errors.name && props.touched.name && (
                          <span className='error-message'>
                            {props.errors.name}
                          </span>
                        )}
                      </div>

                      <div className='input-wrap'>
                        <TextInput
                          name='email'
                          placeholder={t('quote.emailAdress')}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          value={props.values.email}
                        />
                        {props.errors.email && props.touched.email && (
                          <span className='error-message'>
                            {props.errors.email}
                          </span>
                        )}
                      </div>
                      <div className='input-wrap'>
                        <TextInput
                          name='phone'
                          placeholder={t('quote.phoneNumber')}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          value={props.values.phone}
                        />
                        {props.errors.phone && props.touched.phone && (
                          <span className='error-message'>
                            {props.errors.phone}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className='row'>
                      <div className='input-wrap'>
                        <DropdownInput
                          name='car'
                          initialValue={props.values.car}
                          onChange={(value: string) => {
                            let newCar = cars.filter(
                              (item) => item.name === value
                            )[0];
                            history.push({
                              pathname: '/GetQuote/' + newCar.id,
                              params: {},
                            });

                            props.setFieldValue('car', value);
                            props.setFieldValue('transmission', '');
                            setCar(newCar);
                          }}
                          placeholder={t('quote.pickCar')}
                          options={cars.map((item: ICar) => item.name)}
                        />
                        {props.errors.car && props.touched.car && (
                          <span className='error-message'>
                            {props.errors.car}
                          </span>
                        )}
                      </div>
                      <div className='input-wrap'>
                        <DropdownInput
                          name='transmission'
                          initialValue={props.values.transmission}
                          onChange={(value: string) =>
                            props.setFieldValue('transmission', value)
                          }
                          placeholder={t('quote.transmission.placeholder')}
                          options={getCarTransmissionOptions()}
                        />
                        {props.errors.transmission &&
                          props.touched.transmission && (
                            <span className='error-message'>
                              {props.errors.transmission}
                            </span>
                          )}
                      </div>
                      <div className='input-wrap'>
                        <DropdownInput
                          name='pickupLocation'
                          initialValue={props.values.pickupLocation}
                          onChange={(value: string) =>
                            props.setFieldValue('pickupLocation', value)
                          }
                          placeholder={t('quote.pickupLocation')}
                          options={locations
                            .filter((item: any) => item.pickUp)
                            .map((item: any) => item.name)}
                        />
                        {props.errors.pickupLocation &&
                          props.touched.pickupLocation && (
                            <span className='error-message'>
                              {props.errors.pickupLocation}
                            </span>
                          )}
                      </div>
                    </div>
                    <div className='row'>
                      <div className='input-wrap'>
                        <DropdownInput
                          name='returnLocation'
                          initialValue={props.values.returnLocation}
                          onChange={(value: string) =>
                            props.setFieldValue('returnLocation', value)
                          }
                          placeholder={t('quote.returnLocation')}
                          options={locations
                            .filter((item: any) => item.dropOff)
                            .map((item: any) => item.name)}
                        />
                        {props.errors.returnLocation &&
                          props.touched.returnLocation && (
                            <span className='error-message'>
                              {props.errors.returnLocation}
                            </span>
                          )}
                      </div>
                      <div className='input-wrap'>
                        <div className='datepicker-holder'>
                          <span className='calendar-icon'>
                            <ReactSVG src={Calendar} />
                          </span>
                          <Datepicker
                            name='pickupDate'
                            selected={props.values.pickupDate}
                            locale={i18n.language}
                            onChange={(date: Date | null) => {
                              props.setFieldValue('pickupDate', date);
                              if (
                                props.values.returnDate &&
                                date &&
                                props.values.returnDate < date
                              ) {
                                props.setFieldValue('returnDate', null);
                              }
                            }}
                            placeholderText={t('home.reservation.pickupDate')}
                            minDate={new Date()}
                          />
                        </div>
                        {props.errors.pickupDate &&
                          props.touched.pickupDate && (
                            <span className='error-message'>
                              {props.errors.pickupDate}
                            </span>
                          )}
                      </div>
                      <div className='input-wrap'>
                        <div className='datepicker-holder'>
                          <span className='calendar-icon'>
                            <ReactSVG src={Calendar} />
                          </span>
                          <Datepicker
                            name='returnDate'
                            selected={props.values.returnDate}
                            locale={i18n.language}
                            onChange={(date: Date | null) => {
                              props.setFieldValue('returnDate', date);
                            }}
                            placeholderText={t('home.reservation.returnDate')}
                            minDate={
                              props.values.pickupDate
                                ? props.values.pickupDate
                                : new Date()
                            }
                          />
                        </div>
                        {props.errors.returnDate &&
                          props.touched.returnDate && (
                            <span className='error-message'>
                              {props.errors.returnDate}
                            </span>
                          )}
                      </div>
                    </div>
                    <div className='text-area-container'>
                      <textarea
                        className='additionalNote'
                        name='note'
                        value={props.values.note}
                        onChange={props.handleChange}
                        placeholder={t('quote.additionalNote')}
                      />
                    </div>
                  </div>
                </div>
                <div className='finish start'>
                  <span>
                    {t('quote.additionalEquipment.regular1')}
                    <span className='accent'>
                      {t('quote.additionalEquipment.bold1')}
                    </span>
                    {t('quote.additionalEquipment.regular2')}

                    <span className='accent'>
                      {t('quote.additionalEquipment.bold2')}
                    </span>
                    {t('quote.additionalEquipment.regular3')}
                  </span>
                </div>

                <div className='finish'>
                  <button
                    type='button'
                    onClick={async () => {
                      if (!props.isSubmitting) {
                        await props.validateForm().then((errors) => {
                          const keys = Object.keys(errors);
                          if (keys.length > 0) {
                            if (window.innerWidth < 1249) {
                              const position = document.getElementById(
                                'mobile-scrollTo'
                              );
                              if (position) {
                                window.scroll(0, position.offsetTop - 80);
                              }
                            } else {
                              const position = document.getElementById(
                                'desktop-scrollTo'
                              );
                              if (position) {
                                window.scroll(0, position.offsetTop - 80);
                              }
                            }
                          } else {
                            setIsSubmitting(true);
                          }
                        });

                        props.handleSubmit();
                      }
                    }}
                    className='submit'
                  >
                    {props.isSubmitting
                      ? t('quote.submitting')
                      : t('quote.submit')}
                  </button>
                </div>
              </div>
            )}
          </Formik>
        </div>
      );
    }
  }

  return (
    <div className='fullWidth'>
      <div className='quote'>
        <div className='section-top'>
          <div className='quote-content'>
            <h1>{t('quote.heading1')}</h1>
            <h2>
              {t('quote.heading2')}
              <a className='telephone tel-first' href={`tel:${contact.mobile}`}>
                {contact.mobile}
              </a>
              <span className='or'>{t('quote.or')}</span>

              <a className='telephone' href={`tel:${contact.telephone}`}>
                {contact.telephone}
              </a>
            </h2>
          </div>
        </div>
        <main>
          <div className='quote-content'>{mainRender}</div>
        </main>
      </div>
      <Services />
      <Transfers />
      <Footer />
      <Modal
        submiting
        isVisible={isSubmitting}
        message={t('validationMessage.subbmitingRequest')}
      />
      <Modal
        isVisible={isModalVisible}
        message={
          success
            ? t('validationMessage.getQuoteSucces')
            : t('validationMessage.emailReject')
        }
        success={success}
        hideModal={() => {
          setModalVisible(false);
          if (success) {
            history.push('/');
            window.scroll(0, 0);
          }
        }}
      />
    </div>
  );
};

export { GetQuote };

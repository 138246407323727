import React from 'react';
import Loader from 'react-loader-spinner';

export interface ILoadingProps {
  text?: string;
  fullscreen?: boolean;
  background?: string;
  textStyle?: React.CSSProperties;
  loaderColor?: string;
  width?: number;
}

const Loading: React.FC<ILoadingProps> = ({
  text,
  fullscreen,
  background,
  loaderColor,
  textStyle,
  width = 60,
}) => {
  return (
    <div
      className={`loading-container ${fullscreen ? 'fullscreen-loading' : ''} `}
      style={{ background: background ? background : 'transparent' }}
    >
      <div className='loader'>
        <Loader
          type='Circles'
          color={loaderColor ? loaderColor : '#ba202f'}
          width={width}
        />
      </div>

      {text && (
        <p style={textStyle} className='text'>
          {text}
        </p>
      )}
    </div>
  );
};

export { Loading };
